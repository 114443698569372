$avatar-button-size: 28px;

.root {
  min-height: 100%;

  .tabs {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 900;
  }

  .noMatch {
    padding: 20px;
    text-align: center;
  }

  .avatar {
    min-width: 0;
    min-height: 0;
    width: $avatar-button-size;
    height: $avatar-button-size;
    padding: 0;
    margin: 0 6px;
  }
}
