.root {
  line-height: 1.2em;

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
  }

  p {
    margin: 10px 0;
  }

  strong {
    font-weight: bold;
  }

  ol {
    list-style: decimal;
    list-style-position: inside;

    & > li {
      margin: 10px 0;

      & > p:first-child {
        display: inline;
        white-space: normal;
      }
    }
  }

  ul {
    list-style: disc;
    padding-left: 25px;

    li {
      margin: 5px 0;
    }
  }
}
