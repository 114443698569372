@import 'src/styles/variables';
@import 'src/styles/media';

.footer button {
  margin-inline-start: 0 !important;
}

.pdf canvas {
  width: 100% !important;
  height: auto !important;
}
