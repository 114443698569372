@import 'src/styles/variables';
@import 'src/styles/media';

.root {
  .create {
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
      width: 114px;
    }
  }
  .actions {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
    align-items: center;
  }
}

.drawer {
  .status {
    & > * { width: 100%; }
    & > *:first-child,
    & > *:last-child { width: auto; }
  }
  .bots {
    width: 100%;
  }
  .loading {
    width: 100%;
    padding: 20px 0;
  }
  .messages {
    border-radius: 12px;
    overflow: hidden;

    &.assistant {
      border-top-left-radius: 3px;
      background-color: #e1f1f8;
    }
    &.user {
      border-top-right-radius: 3px;
      background-color: #dff4d7;
    }

    li {
      padding: 5px 10px;
      border-block-end-color: var(--background) !important;
    }

    & > div:last-child {
      padding: 2px 10px;
      font-size: 0.8em;
      opacity: 0.6;
      text-align: right;
      background-color: var(--background);
    }
  }
}

.invitation {
  display: flex;
  flex-direction: column;
  gap: 10px;

  textarea {
    cursor: text !important;
    resize: none;
  }
}
