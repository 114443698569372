@import 'src/styles/variables';
@import 'src/styles/media';

.root {
  div:has(> .search) {
    position: sticky;
    top: 38px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    background-color: var(--light-extra);
    border-radius: 0 !important;
    z-index: 2;
  }
  .botsCell > p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .create {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
    margin: 0 auto;

    button {
      flex-grow: 1;
      padding: 0;
    }
  }
  .actions {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
    align-items: center;

    button {
      flex-grow: 1;
    }
  }
}

.sectionTitle {
  margin-bottom: 10px;

  &:not(:first-of-type) {
    margin-top: 20px;
  }

  h5 {
    white-space: nowrap;
    margin-bottom: 2px;
  }

  div[class~="ant-divider"] {
    margin: 0;
    min-width: 0;
  }
}

.drawer {
  [class~="ant-drawer-title"] {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
  }
  .ownedBots {
    margin-top: -10px;
  }
  .role {
    margin-bottom: 10px;

    & > *:first-child {
      width: 100%;
    }
  }
  .bots {
    width: 100%;
  }
  .weakTitle {
    font-size: 0.8em;
    opacity: 0.6;
  }
  .loading {
    width: 100%;
    padding: 20px 0;
  }
  .upload {
    flex-grow: 1;
    margin-right: -1px;
  }
  .hrTextsButtons {
    height: 100%;

    & > div:last-child {
      flex-grow: 1;

      button {
        flex-grow: 1;
      }
    }
  }
  .messagesHeader {
    padding-right: 8px !important;
    -webkit-tap-highlight-color: transparent;
  }
  .messages {
    border-radius: 12px;
    overflow: hidden;

    &.assistant {
      border-top-left-radius: 3px;
      background-color: #e1f1f8;
    }
    &.user {
      border-top-right-radius: 3px;
      background-color: #dff4d7;
    }

    li {
      padding: 5px 10px;
      border-block-end-color: var(--background) !important;
    }

    & > div:last-child {
      padding: 2px 10px;
      font-size: 0.8em;
      opacity: 0.6;
      text-align: right;
      background-color: var(--background);
    }
  }
}

.invitation {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .steps {
    margin: 20px 0;
  }

  .upload {
    flex-grow: 1;
    margin-right: -1px;

    button {
      height: 50px;
    }

    & + button {
      height: auto;
      align-self: stretch;
    }

    .uploadFileName {
      font-size: 0.8em;
      line-height: 1.3em;
    }
  }

  .divider {
    margin: 2px 0;
  }

  div[class~='ant-form-item'] {
    margin-bottom: 0 !important;
  }

  div[class~='ant-form-item-label'] {
    padding-bottom: 0 !important;
    margin-top: -6px !important;
  }

  textarea {
    cursor: text !important;
    resize: none;
  }
}

.invitationFooter button {
  margin-inline-start: 0 !important;
}

.creation {
  display: flex;
  flex-direction: column;

  .password {
    div:first-child {
      flex-grow: 1;
    }
  }
}
