:root {
  --black: #0f1414;
  --white: #fff;

  --grey-5: #fafafa;
  --grey-10: #f5f5ee;
  --grey-20: #e2e2de;
  --grey-30: #ababa6;
  --grey-70: #797976;
  --grey-80: #484846;
  --grey-90: #222221;

  --blue: #0db0f1;
  --blue-dark: #2b84a4;
}
