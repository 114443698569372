@import 'src/styles/variables';
@import 'src/styles/media';

.root {
  overflow: hidden;

  & > div:first-child {
    display: block;

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      span:last-child {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
