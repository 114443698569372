@import 'src/styles/variables';
@import 'src/styles/media';

div:has(.root) {
  height: 100%;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 10px;
  max-width: 400px;
  min-height: 100%;
  margin: 0 auto;
  padding: 20px;

  div {
    margin: 0;
    padding: 0;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 10px;

    @include mobile {
      flex-direction: column;
    }

    button {
      flex-grow: 1;
    }
  }
}
